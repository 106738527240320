import React from "react";
import { render } from "react-dom";
import initAnalytic from "utils/analytic";
import {
  DEV_MODE,
  SENTRY_KEY,
  SENTRY_PROJECT,
  NODE_ENV,
} from "utils/constants";
import * as Sentry from "@sentry/browser";

const DOUBLE_SLASH_REGEX = /\/{2,}/g;
const { location } = window;
const { pathname, href } = window.location;
if (DOUBLE_SLASH_REGEX.test(pathname)) {
  location.replace(href.replace(pathname, pathname.replace(DOUBLE_SLASH_REGEX, "/")));
}

if (!DEV_MODE) {
  initAnalytic();
  Sentry.init({
    dsn: `https://${SENTRY_KEY}@sentry.io/${SENTRY_PROJECT}`,
    environment: NODE_ENV,
    release: "REACT_SCRIPTS",
  });
}

((async () => {
  const { default: App } = await import("./App");
  render(<App />, document.getElementById("root"));
})());

if (DOUBLE_SLASH_REGEX.test(pathname)) {
  location.replace(href.replace(pathname, pathname.replace(DOUBLE_SLASH_REGEX, "/")));
} else {
  ((async () => {
    const { default: App } = await import("./App");
    render(<App />, document.getElementById("root"));
  })());
}
